import React from 'react';
import { motion } from 'framer-motion';

const TermsOfPayment = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7 }}
      className='block'
    >
      <h1 className='title-header'>Условия оплаты</h1>
      <div className='company-block'>
        <h5>
          Правила оплаты и безопасность платежей, конфиденциальность информации
        </h5>
        <p className='company-text'>
          Оплата банковскими картами осуществляется через АО «АЛЬФА-БАНК» или
          ПАО РОСБАНК.
        </p>
        <p className='company-text'>
          К оплате принимаются карты VISA, MasterCard, МИР.
        </p>
        <p className='company-text'>
          Услуга оплаты через интернет осуществляется в соответствии с Правилами
          международных платежных систем Visa, MasterCard и Платежной системы
          МИР на принципах соблюдения конфиденциальности и безопасности
          совершения платежа, для чего используются самые современные методы
          проверки, шифрования и передачи данных по закрытым каналам связи. Ввод
          данных банковской карты осуществляется на защищенной платежной
          странице АО «АЛЬФА-БАНК».
        </p>
        <p className='company-text'>
          На странице для ввода данных банковской карты потребуется ввести{' '}
          <b style={{ color: '#000' }}>данные банковской карты</b>: номер карты,
          имя владельца карты, срок действия карты, трёхзначный код безопасности
          (CVV2 для VISA, CVC2 для MasterCard, Код Дополнительной Идентификации
          для МИР). Все необходимые данные пропечатаны на самой карте.
          Трёхзначный код безопасности — это три цифры, находящиеся на обратной
          стороне карты.
        </p>
        <p className='company-text'>
          Далее вы будете перенаправлены на страницу Вашего банка для ввода кода
          безопасности, который придет к Вам в СМС. Если код безопасности к Вам
          не пришел, то следует обратиться в банк выдавший Вам карту.
        </p>
        <p className='company-text'>Случаи отказа в совершении платежа:</p>
        <ul>
          <li style={{ color: '#00adab' }}>
            <span className='company-text'>
              банковская карта не предназначена для совершения платежей через
              интернет, о чем можно узнать, обратившись в Ваш Банк;
            </span>
          </li>
          <li style={{ color: '#00adab' }}>
            <span className='company-text'>
              недостаточно средств для оплаты на банковской карте. Подробнее о
              наличии средств на банковской карте Вы можете узнать, обратившись
              в банк, выпустивший банковскую карту;
            </span>
          </li>
          <li style={{ color: '#00adab' }}>
            <span className='company-text'>
              данные банковской карты введены неверно;
            </span>
          </li>
          <li style={{ color: '#00adab' }}>
            <span className='company-text'>
              истек срок действия банковской карты. Срок действия карты, как
              правило, указан на лицевой стороне карты (это месяц и год, до
              которого действительна карта). Подробнее о сроке действия карты Вы
              можете узнать, обратившись в банк, выпустивший банковскую карту;
            </span>
          </li>
        </ul>
        <br />
        <p className='company-text'>
          По вопросам оплаты с помощью банковской карты и иным вопросам,
          связанным с работой сайта, Вы можете обращаться по следующему
          телефону: <b style={{ color: '#000' }}>8 800 301-02-99.</b>{' '}
        </p>
        <p className='company-text'>
          Предоставляемая вами персональная информация (имя, адрес, телефон,
          e-mail, номер банковской карты) является конфиденциальной и не
          подлежит разглашению. Данные вашей кредитной карты передаются только в
          зашифрованном виде и не сохраняются на нашем Web-сервере.
        </p>

        <h5>
          Правила оплаты и безопасность платежей, конфиденциальность информации
        </h5>
        <p className='company-text'>
          При оплате заказа банковской картой, обработка платежа (включая ввод
          номера карты) происходит на защищенной странице процессинговой
          системы, которая прошла международную сертификацию. Это значит, что
          Ваши конфиденциальные данные (реквизиты карты, регистрационные данные
          и др.) не поступают в интернет-магазин, их обработка полностью
          защищена и никто, в том числе{' '}
          <b style={{ color: '#000' }}>наш интернет-магазин</b>, не может
          получить персональные и банковские данные клиента. При работе с
          карточными данными применяется стандарт защиты информации,
          разработанный международными платёжными системами Visa и Mastercard -
          Payment Card Industry Data Security Standard (PCI DSS), что
          обеспечивает безопасную обработку реквизитов Банковской карты
          Держателя. Применяемая технология передачи данных гарантирует
          безопасность по сделкам с Банковскими картами путем использования
          протоколов Transport Layer Security (TLS), Verified by Visa, Secure
          Code, и закрытых банковских сетей, имеющих высшую степень защиты. В
          случае возврата, денежные средства возвращаются на ту же карту, с
          которой производилась оплата.
        </p>
      </div>
    </motion.div>
  );
};

export default TermsOfPayment;
