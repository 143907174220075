import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Header } from './components/Header/Header';
import { Men } from './components/Perfume/Men/Men';
import { Women } from './components/Perfume/Women/Women';
import { Page } from './components/Perfume/Page/Page';
import { Unisex } from './components/Perfume/Unisex/Unisex';
import { Footer } from './components/Footer/Footer';
import { Basket } from './components/Basket/Basket';
import { Catalog } from './components/Catalog/Catalog';
import { BasketBtn } from './components/Basket/BasketBtn/BasketBtn';
import Main from './components/Main/Main';
import About from './components/Footer/Pages/About/About';
import TermsOfPayment from './components/Footer/Pages/TermsOfPayment/TermsOfPayment';
import Delivery from './components/Footer/Pages/Delivery/Delivery';
import Certificate from './components/Footer/Pages/Certificate/Certificate';
import NotFound from './components/NotFound/NotFound';
import { Diffusers } from './components/Diffusers/Diffusers';

function App() {
  const [perfumes, setPerfumes] = useState({
    men: [
      {
        id: 1,
        name: 'M001',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m001.jpg',
        aroma: 'шипровый',
        top_notes: 'лимон, бергамот, лайм, лаванда и чабрец',
        heart_notes:
          'яблоко, цветок апельсина, черная смородина, чампака, жасмин, лилия и кокос',
        base_notes:
          'плоды можжевельника, береза, кедр, розовый перец, кожа, дубовый мох, пачули, мате, бобы тонка, корень фиалки, анис, мускус, ваниль и амбра',
        description:
          'Аромат настоящего мужчины. Сложносочиненная композиция раскрывается невероятно мужественным, уверенным, властным звучанием. Насыщенный цитрусовый букет смело переплетающийся с абсолютно не женственно звучащими в этом аромате цветами, прохладой черной смородины и свежестью кокоса и завершающийся пряно-древесным сдержанно-сладким шлейфом.',
        perfume: 'Созвучен Roja Dove - Oligarch',
      },
      {
        id: 2,
        name: 'M002',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m002.jpg',
        aroma: 'древесный',
        top_notes: 'лимон, мята, розовый перец и грейпфрут',
        heart_notes: 'имбирь, мускатный орех и жасмин',
        base_notes:
          ' лабданум, сандал, пачули, ветивер, ладан, белый кедр и белый мускус',
        description:
          'Аромат глубокий и древесный для ценителей свободы. Мята и грейпфрут добавляют аромату свежести и бодрости, оживляя благородный древесный шлейф, а пряные нотки добавляют той самой "перчинки" в образ настоящего, уверенного в себе мужчины.',
        perfume: 'Созвучен Chanel - Bleu de Chanel',
      },
      {
        id: 3,
        name: 'M003',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m003.jpg',
        aroma: 'древесный, фужерный',
        top_notes: 'можжевельник, лимон, бергамот и перец',
        heart_notes: 'иглы сосны, ладан и корень ириса',
        base_notes: 'сандал, ваниль и амбра',
        description:
          'Аромат настоящего единения с природой. Густой хвойный лес, путешествие дикарем с рюкзаком на плечах и минимальной провизией, испытание для самых смелых. Образ такого мужчины стал вдохновением для создания данного аромата. Отчетливый хвойно-лиственный аромат со сдержанным добавлением пряностей и нотками цитрусов.',
        perfume: 'Созвучен BYREDO - Gypsy Water',
      },
      {
        id: 4,
        name: 'M004',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m004.jpg',
        aroma: 'шипровый, фруктовый',
        top_notes: 'бергамот, черная смородина, ананас и яблоко',
        heart_notes: 'береза, пачули, роза и жасмин',
        base_notes: 'мускус, дубовый мох, ваниль и серая амбра',
        description:
          'Аромат необычайно оптимистичный, завлекающий, игривый и утонченный. Цветочно-фруктовый букет удивительно подобран максимально выражающим и подчеркивающим мужественность, а богатый древесный шлейф делают аромат теплым, внушающим доверие и надежность.',
        perfume: 'Созвучен Creed - Aventus for Him',
      },
      {
        id: 5,
        name: 'M005',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m005.jpg',
        aroma: 'восточный, древесный',
        top_notes: 'перец, кардамон',
        heart_notes: 'бобы тонка, розовое дерево, ваниль',
        base_notes: 'амбра, уд, сандал, ветивер',
        description:
          'Аромат -прекрасный представитель древесных композиций. Густой, немного терпкий, уютный и в меру пряный. При этом абсолютно нетяжелый и ненавязчивый. Безупречно подобранные ингредиенты делают звучание данного аромата естественным и неповторимым.',
        perfume: 'Созвучен Tom Ford - Oud Wood',
      },
      {
        id: 6,
        name: 'M006',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m006.jpg',
        aroma: 'восточный, древесный',
        top_notes: 'орегано, перец и бергамот',
        heart_notes: 'ладан, опопонакс, амбра и лабданум',
        base_notes: 'кожа, уд, сандал и пачули',
        description:
          'Аромат изящного ладана, обыгранного пленительными специями с примесью легкой сладости и древесно - кожаным, словно дыхание самой природы, шлейфом. Окутывющий, обволакивающий и успокаивающий.',
        perfume: 'Созвучен Amouage - Interlude Man',
      },
      {
        id: 7,
        name: 'M007',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m007.jpg',
        aroma: 'древесный, фужерный',
        top_notes: 'сицилийский мандарин и розмарин',
        heart_notes: 'амирис, ирис, молочный шоколад, кокос и кофе',
        base_notes: 'бобы тонка и уд',
        description:
          'Аромат мягкий, как теплый закат на море со сладкими переливами мандарина, бобов тонка и, конечно, молочного шоколада, будоражащий сознание мятно-ирисными и кофейными нотками, но остающийся уверенно мужественным, благодаря тонким, но уверенным аккордам специфичного розмарина и соблазняющего уда.',
        perfume: 'Созвучен Maison Francis Kurkdjian - Amyris Homme',
      },
      {
        id: 8,
        name: 'M008',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m008.jpg',
        aroma: 'древесный, пряный',
        top_notes: 'альдегиды, апельсин, красный мандарин и морские ноты',
        heart_notes: 'перец, нероли и белый кедр',
        base_notes: 'бобы тонка, амбра, ваниль, ветивер, белый мускус и элеми',
        description:
          'Аромат прообраз мужчины, намеревающегося превзойти собственные силы. Смесь цитрусовых и морских аккордов, пряностей и благородных древесных пород, выражает смелость, упорство, отвагу и целеустремленность. Свежий и чувственный с интенсивным и глубоким шлейфом.',
        perfume: 'Созвучен chanel - Allure Homme Sport',
      },
      {
        id: 9,
        name: 'M009',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m009.jpg',
        aroma: 'кожаный',
        top_notes: 'малина, шафран и чабрец',
        heart_notes: 'олибанум и жасмин',
        base_notes: 'кожа, замша, древесные ноты и амбра',
        description:
          'Аромат благородной кожи. Сдержанный, спокойный и уверенный, с примесью горького шоколада с малиной, декорированный запахом искусно вырезанной картины по дереву лучшим мастером города. Сексуален от начала до самого последнего отголоска долгого шлейфа.',
        perfume: 'Созвучен Tom Ford - Tuscan Leather',
      },
      {
        id: 10,
        name: 'M010',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m010.jpg',
        aroma: 'цветочный, мускусный',
        top_notes: 'грейпфрут и мандарин',
        heart_notes: 'фиалка, мята и базилик',
        base_notes: 'кожа и ветивер',
        description:
          'Аромат, которым невозможно надышаться. Он не надоедает, его хочется ощущать снова и снова, ведь каждый раз он удивляет, каждый раз можно уловить новые ноты. Освежающее, с горчинкой грейпфрута начало представлено тонизирующим коктейлем из мандарина, мяты, базилика... Кожаная куртка смелого летчика, сброшенная на траву от жары-шлейф аромата',
        perfume: 'Созвучен Dior - Aqua Fahrenheit',
      },
      {
        id: 11,
        name: 'M011',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m011.jpg',
        aroma: 'древесный, цветочный',
        top_notes: 'лаванда, розмарин, нероли и петитгрейн',
        heart_notes: 'герань, мускатный шалфей, гальбанум и жасмин',
        base_notes: 'дубовый мох, ветивер, кедр, сандал и амбра',
        description:
          'Энергичный и провоцирующий. Аромат для экстравертов, которым свойственно быть в центре внимания. Для тех, кто обладает естественной харизмой. Он всегда свеж на идеи, он привык принимать важные решения на ходу и они всегда оказываются верными. Когда его нет - это сразу заметно, как будто создается пустота. За ним хочется следовать везде и всюду, ему точно можно доверить управление и быть уверенным, что он найдет правильный выход из любой ситуации.',
        perfume: 'Созвучен Chanel - Egoiste Platinum',
      },
      {
        id: 12,
        name: 'M012',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m012.jpg',
        aroma: 'древесный, морской',
        top_notes: 'морские ноты, грейпфрут и мандарин',
        heart_notes: 'лавр и жасмин',
        base_notes: 'серая амбра, гваяк, дубовый мох и пачули',
        description:
          'Аромат настоящей легенды, героя нашего времени. Воплощение благородства и нравственности. Для галантных и уверенных мужчин, которым свойственны хорошие, даже величественные поступки во имя истинных ценностей. Свежесть морских нот и цитрусовых придают еще больше энергии для осуществления правых дел, а древесные ноты не оставляют шанса усомниться в величии и чувстве справедливости.',
        perfume: 'Созвучен Paco Rabanne - Invictus',
      },
      {
        id: 13,
        name: 'M013',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m013.jpg',
        aroma: 'древесный, гурманский',
        top_notes: 'яблоко, слива, лимон, бергамот, дубовый мох и герань',
        heart_notes: 'корица, махагони и гвоздика',
        base_notes: 'ваниль, сандал, кедр, ветивер и оливковое дерево',
        description:
          'Свежий, древесный, немного пряный. Для тех, кто не только знает, чего хочет добиться, но и уверен, что добьётся этого на 100%. Он решительный, целеустремленный, амбициозный. Он пробует снова и снова, потому что знает, что в итоге все будет так, как и должно быть. Его не смущают неудачи, он просто анализирует свои ошибки и исправляет их, получая еще большую выгоду.',
        perfume: 'Созвучен HUGO BOSS - Boss Bottled',
      },
      {
        id: 14,
        name: 'M014',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m014.jpg',
        aroma: 'фужерный, морской',
        top_notes: 'лайм, лимон, бергамот, жасмин, апельсин, мандарин и нероли',
        heart_notes:
          'морские ноты, жасмин, калон, персик, фрезия, розмарин, гиацинт, цикламен, кориандр, фиалка, мускатный орех, роза и резеда',
        base_notes: 'белый мускус, кедр, дубовый мох, пачули и амбра',
        description:
          'Россыпь спелых цитрусовых на ветках, растущих вдоль моря деревьев. Зеркальный, чистый, прохладный, по-настоящему освежающий. Универсален, подойдет для любого случая и идеально дополнит и подчеркнет абсолютно любой образ. Квинтэссенция мужских ароматов.',
        perfume: 'Созвучен Giorgio Armani - Acqua Di Gio',
      },
      {
        id: 15,
        name: 'M015',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/m015.jpg',
        aroma: 'фужерный',
        top_notes: 'бергамот и перец',
        heart_notes:
          'сычуанский перец, лаванда, розовый перец, ветивер, пачули, герань и элеми',
        base_notes: 'ambroxan, кедр и лабданум.',
        description:
          'Резкое и смелое начало, уверенное и исключительное сердце, оставляющий благородный след, шлейф. Как загадочный незнакомец - дикий и непредсказуемый. Сначала ошеломляет тебя своим появлением, затем пытаешься его разгадать, ломая голову, каким он окажется. Но вскоре, все резко становится очевидным и появляется необъяснимая уверенность в его искренности, надежности и благородстве.',
        perfume: 'Созвучен Dior - Sauvage',
      },
    ],
    women: [
      {
        id: 16,
        name: 'F101',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/men/f101.jpg',
        aroma: 'цветочный, фруктовый',
        top_notes: 'жасмин, османтус и майская роза',
        heart_notes: 'тубероза и нарцисс',
        base_notes: 'амбра и белый кедр',
        description:
          'Аромат одновременно невинный и дерзкий, особенный и провокационный, максимально подчеркивает индивидуальность его обладателя. На коже раскрывается постепенно, нота за нотой, благодаря этому звучит в течение дня по-разному, словно вы меняете парфюм в зависимости от времени суток и настроения под стать ему, а потом еще долго остаются слышны оттенки всех нот на одежде и волосах. Вначале улавливаются теплые нотки жасмина и майской розы с оттенком загадочного восточного османтуса, затем выступают магическая тубероза и гордый нарцисс, заряжая уверенностью в себе, и в завершении дня успокаивают и придают игривость древесные нотки белого кедра и амбры.',
        perfume: 'Созвучен Kilian - Good Girl Gone Bad',
      },
      {
        id: 17,
        name: 'F102',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f102.png',
        aroma: 'цветочный, фруктовый',
        top_notes: 'морская соль',
        heart_notes: 'жасмин самбак, иланг-иланг, нероли и тубероза',
        base_notes: 'персик',
        description:
          'Аромат сладкий, благодаря вкусному безупречному сочетанию в нем нот жасмина, иланг-иланга, нероли и сочного персика, свежий, благодаря неожиданной и такой пикантной морской соленой ноте, мистический, благодаря манящей туберозе. Навевает приятные воспоминания о морском отдыхе.',
        perfume: "Созвучен Dior - J'adore in Joy",
      },
      {
        id: 18,
        name: 'F103',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f103.jpg',
        aroma: 'цветочный',
        top_notes: 'бергамот, ревень, личи и мускатный орех',
        heart_notes:
          'турецкая роза, пион, петалия (вещество с запахом пиона), ваниль и мускус',
        base_notes: 'кашмеран, ветивер, белый кедр и ладан',
        description:
          'Аромат сама изящность и утонченность. Рекомендуется для истинных леди, нежных, но грациозных и знающих себе цену. Яркое и заигрывающее фруктово-цитрусовое начало, безукоризненно прекрасное сердце представляется в виде букета спелых роз и пионов, в который добавили настоящей ванили для демонстрации его особенности и важности, загадочный сладковато-древесный шлейф, который не может не обратить на себя внимание.',
        perfume: 'Созвучен Parfums de Marly - Delina',
      },
      {
        id: 19,
        name: 'F104',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f104.png',
        aroma: 'цветочный, фруктовый',
        top_notes: 'айва и грейпфрут',
        heart_notes: 'гиацинт и жасмин',
        base_notes: 'мускус, ирис, вирджинский кедр и амбра',
        description:
          'Аромат красивый, женственный, близкий к классике; аромат беззаботной француженки, гуляющей по Парижу. Легкость и свежесть аромату придает фруктовое начало, цветочное сердце делает его мягким и чистым, а утонченный древесный шлейф в сочетании с амброй наделяют аромат изысканностью и загадочностью.',
        perfume: 'Созвучен CHANEL - Chance eau tendre',
      },
      {
        id: 20,
        name: 'F105',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f105.jpg',
        aroma: 'кожаный',
        top_notes: 'лайм, розовый перец',
        heart_notes: 'роза, можжевельник',
        base_notes: 'замша, кедр, ветивер, белый мускус',
        description:
          'Аромат базируется на отчетливой кожано-замшевой ноте, но так искусно окруженной лаймом, древесными и цветочными нотками, что делает его безукоризненно привлекательным и манящим. В меру небрежный, стильный, естественный и безумно интересный.',
        perfume: 'Созвучен Memo Paris - French Leather ',
      },
      {
        id: 21,
        name: 'F106',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f106.png',
        aroma: 'восточный, цветочный',
        top_notes: 'фисташки, бергамот, кардамон и розовый перец',
        heart_notes: 'тубероза, иланг-иланг и жасмин',
        base_notes: 'кокос, амбра, бобы тонка и бензоин',
        description:
          'Аромат круглогодичного лета. Манящие острова, бескрайний океан, идеально ясное небо... Прохладная тень от нависающих пальм... Теплый, но освежающий, понятный, но неповторимый, где пряные ноты кардамона и розового перца изысканно дополняются легким оттенком бергамота и фисташек, смягчаются нежными и благородными иланг-илангом и жасмином. Все это плавно окутано сладким тропическим кокосом, бобами тонка и завлекающей амброй.',
        perfume: 'Созвучен Tom Ford - Soleil Blanc',
      },
      {
        id: 22,
        name: 'F107',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f107.png',
        aroma: 'восточный, фужерный',
        top_notes: 'лаванда, мандарин, черная смородина и петитгрейн',
        heart_notes: 'лаванда, цветок апельсина и жасмин',
        base_notes: 'мадагаскарская ваниль, мускус, серая амбра и кедр',
        description:
          'Аромат свободы, свободы от предрассудков, постоянная грань между дозволенным и запрещенным. Дерзкий мандарин в тандеме с освежающей черной смородиной и завораживающей лавандой и натуральностью травяных и древесных нот рождают неповторимый аромат, серая амбра и мускус добавляют в него сексуальности, а мадагаскарская ваниль завершает все сладким поцелуем.',
        perfume: 'Созвучен Yves Saint Laurent - Libre ',
      },
      {
        id: 23,
        name: 'F108',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f108.jpg',
        aroma: 'восточный, цветочный',
        top_notes: 'вишня, горький миндаль и ликер',
        heart_notes: 'вишня, слива, турецкая роза и жасмин самбак',
        base_notes:
          'бобы тонка, ваниль, перуанский бальзам, корица, бензоин, сандал, кедр, гвоздика (пряность), ветивер и пачули',
        description:
          'Аромат сексуально - сладкий с изысканной орехово-ликерной горчинкой. Притягательный и совершенный. Дразнящий и глубокий. Соблазнительная вишня с терпким миндалем и ликером вначале, вишнево-цветочный ликер в сердце аромата и утонченный сладко-древесный шлейф.',
        perfume: 'Созвучен Tom Ford - Lost Cherry ',
      },
      {
        id: 24,
        name: 'F109',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f109.jpg',
        aroma: 'восточный, гурманский',
        top_notes: 'фиалка, бергамот и апельсин',
        heart_notes: 'кофе, груша, ирис, цветок апельсина и роза',
        base_notes: 'карамель, ваниль и пачули',
        description:
          'Аромат настоящих гурманов и ценителей вкуса во всем, что их окружает. Непредсказуемое раскрытие на коже необычного сочетания фиалки и цитрусов с кофе и пряностями, дополненное фруктами и свежими цветами делает этот аромат уникальным каждый раз.',
        perfume: 'Созвучен DOLCE & GABBANA - The Only One 2 ',
      },
      {
        id: 25,
        name: 'F110',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f110.jpg',
        aroma: 'цветочный, фруктовый',
        top_notes: 'манго, фиалка и апельсин',
        heart_notes: 'франжипани, цветок манго и иланг-иланг',
        base_notes: 'черная смородина, красное вино, мускус и цибетин',
        description:
          'Аромат удовольствия. Свежесть и сочность манго. В нем есть все: радость, счастье, настроение. В сочетании с тропическими цветами, цитрусовыми отголосками, прохладой черной смородины и легкой терпкостью красного вина дарит неповторимое звучание и наслаждение.',
        perfume: 'Созвучен Vilhelm Parfumerie - Mango Skin',
      },
      {
        id: 26,
        name: 'F111',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f111.jpg',
        aroma: 'восточный, гурманский',
        top_notes: 'груша, розовый перец и цветок апельсина',
        heart_notes: 'кофе, жасмин, горький миндаль и лакричник',
        base_notes: 'ваниль, пачули, кедр и кашемировое дерево',
        description:
          'Омут, из которого сложно выбраться, сладкий плен, соблазнительное удовольствие - все это характеризует этот аромат. Страстный и манящий, но при этом теплый и даже уютный. Восточная гурманика со вкусом кофе амаретто в окружении цветов и сладкой груши. Легко влюбиться и невозможно насытиться.',
        perfume: 'Созвучен Yves Saint Laurent - Black Opium',
      },
      {
        id: 27,
        name: 'F112',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f112.jpg',
        aroma: 'шипровый, цветочный',
        top_notes: 'груша и бергамот',
        heart_notes: 'роза, жасмин',
        base_notes: 'белый мускус и ваниль',
        description:
          'Благородный и чистый аромат. Нежная и мягкая шипровость придает аромату изысканности. Роза в сердце аромата, обволакивает и создаёт нежноцветочную вуаль. Безупречный жасмин наделяет аромат бархатистостью. Ноты шипра наделяют ощущением твердости и решительности. Ваниль добавляет утонченности.',
        perfume: 'Созвучен Lancome - Idole ',
      },
      {
        id: 28,
        name: 'F113',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f113.png',
        aroma: 'восточный, цветочный',
        top_notes: 'груша и бергамот',
        heart_notes: 'тубероза, цветок апельсина и жасмин самбак',
        base_notes: 'пачули, ваниль, ambroxan и ветивер',
        description:
          'Аромат универсальный и современный, но в то же время неповторимый и своеобразный. Сочетается как с вечерним платьем, так и естественно подойдет к грубым ботинкам с косухой. Мягкая легкость и сладость груши, благодаря бергамоту, звучат объемнее. Изысканный жасмин, обрамленный цветами апельсина и туберозой в сердце аромата раскрывают его, и завершает звучание ванильно - притягательный шлейф . Композиция заряжает энергией и желанием расширять границы.',
        perfume: " Созвучен Givenchy - L'Interdit Eau de Parfum ",
      },
      {
        id: 29,
        name: 'F114',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f114.png',
        aroma: 'восточный, цветочный',
        top_notes: 'миндаль, мандарин и нероли',
        heart_notes: 'орхидея, гелиотроп, иланг-иланг, пион и жасмин самбак',
        base_notes: 'ваниль, бензоин, мускус, лабданум, древесные ноты и кедр',
        description:
          'Аромат с поистине женским характером. Сочетает в себе соблазнительность и чувственность. Не кричащий, роскошный, нежный и обволакивающий. Выверенная сладость с легкой кислинкой цитрусов и еле заметной терпкостью миндаля, утонченное цветочное звучание и манящий, шелковистый шлейф.',
        perfume: 'Созвучен LM Parfums - Sensual Orchid',
      },
      {
        id: 30,
        name: 'F115',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f115.jpg',
        aroma: 'цветочный, древесный',
        top_notes: 'османтус и белый чай',
        heart_notes: 'сандал и цветок апельсина',
        base_notes: 'ваниль, кашмеран и древесные ноты',
        description:
          'Грань искушения перед выбором. Какой быть сегодня? Мягкой или дерзкой? Честной или слукавить? Нежной или соблазнительной? Решать только тебе. Одно ясно - отдайся наслаждению этим ароматом до конца. Позволь окутать себя ванильным и мягким, словно кашемир, сном. Ощути изысканность цветов апельсина и сандала. Почувствуй теплоту и спокойствие после глотка белого чая.',
        perfume: "Созвучен HFC - Devil's Intrigue",
      },
      {
        id: 31,
        name: 'F116',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f116.png',
        aroma: 'цветочный, фруктовый',
        top_notes: 'малина, черная смородина, гранат и розовый перец',
        heart_notes: 'роза и пион',
        base_notes: 'белый мускус',
        description:
          'Женственный и игривый. Яркий цветочно-сладкий взрыв эмоций. Припудренная черная смородина, с ярко выраженной нотой сладкой малины, благоухающие роза и пион и весь этот букет органично уравновешивается белым мускусом. Волшебный розовый аромат для загадочных мечтательниц.',
        perfume: 'Созвучен Dior - Miss Dior Absolutely Blooming ',
      },
      {
        id: 32,
        name: 'F117',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f117.png',
        aroma: 'древесный, цветочный',
        top_notes: 'жасмин, болгарская роза и цветок апельсина',
        heart_notes: 'мускус',
        base_notes: 'кумарин, кедр, ветивер и пачули',
        description:
          'Завораживающий, изысканный, бархатный и выразительный. Пудровый аромат, обволакивает с головы до ног. Вас однозначно будут узнавать по аромату. Раскрывается нежными нотами цветов апельсина, розой и жасмином. Манящие ноты сердца - благородный мускус. А насыщенный древесносладкий шлейф еще надолго остается на вашей коже.',
        perfume: 'Созвучен Narciso Rodriguez - Narciso Poudree',
      },
      {
        id: 33,
        name: 'F118',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f118.jpg',
        aroma: 'цветочный, фруктовый',
        top_notes: 'лимон и яблоко',
        heart_notes: 'жасмин и календула',
        base_notes: 'мускус и amberwood',
        description:
          'Лёгкий, свежий, в меру сладкий, игривый. Аромат, как базовый топ в гардеробе, который сочетается с любой вашей одеждой, будь то джинсы в выходные, строгая юбка - карандаш, надетая в офис или элегантный вечерний костюм в пятничный вечер. Освежающие цитрус и яблоко в начале, элегантный жасмин с загадочной календулой в сердце, и, звучащий на протяжении всей композиции, раскрывающийся в шлейфе, уверенный мускус.',
        perfume: 'Созвучен Dolce & Gabbana - Light Blue Eau Intense',
      },
      {
        id: 34,
        name: 'F119',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f119.jpg',
        aroma: 'восточный, цветочный',
        top_notes: 'роза, гвоздика, малина, черная смородина и корица',
        heart_notes: 'пачули, ладан и сандал',
        base_notes: 'мускус, бензоин и амбра',
        description:
          'Богатый, роскошный аромат. Наполнен пышным цветочным букетом, достойным самых прекрасных, красивых и желанных леди. Приправлен пряностями и благородными мускусом с амброй, что добавляет ему немного ретро звучания, напоминая леди в средние века на званном вечере в дымном ладанном облаке.',
        perfume: 'Созвучен Frederic Malle - Portrait of a Lady ',
      },
      {
        id: 35,
        name: 'F120',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f120.jpg',
        aroma: 'цветочный, фруктовый',
        top_notes: 'зеленая сирень, лист чая',
        heart_notes: 'пион, цветы персика',
        base_notes: 'амбра, мускус, китайский османтус, птитгрейн и кедр',
        description:
          'Аромат, как шедевр высокого искусства. Непревзойденное творение, достижение мастерства. Ненавязчивый и легкий, но в то же время притягательный, как главная героиня данной композиции - сирень. Неприторную сладость оттеняет и придает ей легкую терпкость лист чая. Состав аромата может показаться простым, но многогранные переплетения звучащих в нем нот, делают его гениальным. В точности, как и в искусстве: все гениальное - просто.',
        perfume: "Созвучен Lanvin - Éclat d'Arpège",
      },
      {
        id: 36,
        name: 'F121',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f121.png',
        aroma: 'цветочный, фруктовый',
        top_notes: 'юзу, гранат',
        heart_notes: 'пион, лотос, магнолия',
        base_notes: 'мускус, махагони, амбра',
        description:
          'Аромат про чистоту и беззаботность, про легкость и вечную молодость души. Воплощение кристальной женственности. Уникален из-за свойства по-разному раскрываться в зависимости от времени года. Экзотический юзу и сочный гранат в чуть спешном начале, благородный белый букет в сердце и густой древесно-мускусный шлейф.',
        perfume: 'Созвучен Versace - Bright Crystal',
      },
      {
        id: 37,
        name: 'F122',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f122.jpg',
        aroma: 'восточный, древесный',
        top_notes: 'жасмин',
        heart_notes: 'древесные ноты',
        base_notes: 'амбра',
        description:
          'Не такой как все. Этот восточно-древесный аромат для женщин, которые приручили энергию внутри. Чарующий, манящий, вкусный, свежий и шлейфовый. Поразительно многогранный аромат, в котором легко заблудиться.',
        perfume: 'Созвучен Thierry Mugler - Alien',
      },
      {
        id: 38,
        name: 'F123',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f123.png',
        aroma: 'цветочный, древесно-мускусный',
        top_notes: 'перец, фрезия, яблоко',
        heart_notes: 'гелиотроп, фиалка, жасмин, гибискус, роза',
        base_notes: 'замша, сандал, ладан, кедр, лабданум',
        description:
          'Вневременной, базовый, стильный. Замшевая нежность, легкая перечная терпкость, сдержанная цветочность, легкая кислинка. Все это медленно раскрывается и переливается. Уютен и элегантен одновременно.',
        perfume: 'Созвучен Lacoste - pour Femme',
      },
      {
        id: 39,
        name: 'F124',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f124.png',
        aroma: 'восточный, цветочный',
        top_notes: 'мандарин, бергамот, фрезия',
        heart_notes: 'цветок апельсина, белые цветы, ирис, жасмин самбак',
        base_notes: 'ваниль, сандал, пачули',
        description:
          'Нежное счастье сияющих глаз, искренность застенчивой улыбки, подаренные любимым. Очаровательный, непосредственный и стойкий. Цитрусовое вступление, цветочное сердце, пряный и сладостный шлейф.',
        perfume: "Созвучен Lancome -La Vie est Belle L'Eclat",
      },
      {
        id: 40,
        name: 'F125',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f125.png',
        aroma: 'цветочный',
        top_notes: 'жасмин',
        heart_notes: 'тубероза',
        base_notes: 'квисквалис',
        description:
          'Сначала может показаться чересчур сладким и резким, но уже через секунды раскрывается по-настоящему - чуть терпко и нежно. Чрезвычайно цветочный, позитивный, манящий и чарующий. Нестандартное звучание жасмина в аромате, дополнено всегда таинственной туберозой, - в тандеме они подобно белоснежной фате, призванной придавать дополнительной загадочности образу невесты. Квисквалис в базе со свойственным ему ярким ароматом с фруктово-кокосовыми нотками идеально дополняет этот союз и делает его полноценным.',
        perfume: 'Созвучен GUCCI - Bloom',
      },
      {
        id: 41,
        name: 'F126',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f126.jpg',
        aroma: 'восточный, пряный',
        top_notes: 'цитрусы, бергамот, лимон, кедр, мандарин',
        heart_notes: 'ирис, пачули, ветивер, жасмин, роза',
        base_notes:
          'ладан, ваниль, кожа, опопонакс, сандал, цибетин, бобы тонка, мускус',
        description:
          'Аромат, о котором хочется говорить бесконечно. Настоящая 1001 ночь среди парфюмерии. Каждая нота отзывается в нем своей собственной сказкой. Удивительно многогранный и гармоничный аромат. Цитрусово-древесная гамма с легкой горчинкой, изысканные цветы, мистический ладан, вызывающие кожаные ноты и, наконец, ванильный, абсолютно не приторный, теплый и пряный шлейф.',
        perfume: 'Созвучен Guerlain - Shalimar Eau de Parfum',
      },
      {
        id: 42,
        name: 'F127',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f127.png',
        aroma: 'восточный, древесный',
        top_notes: 'апельсин, бергамот, лимон',
        heart_notes: 'роза, фруктовые ноты, жасмин',
        base_notes: 'пачули, бобы тонка, ваниль, белый мускус, лабданум',
        description:
          'Интеллигентный, не колкий, не кричащий аромат. В меру цитрусов, в меру сладости, в меру цветов - все выверено. Здесь классически различимы все этапы раскрытия. На старте бодрит цитрусовый букет, который затихает, но продолжает звучать на протяжении всей композиции. Сердце радует сладким цветочно-фруктовым тандемом. Немного затянувшаяся база проявляется чуть запоздало, когда ее уже не ждешь, но это самый волшебный этап истинного раскрытия аромата. Бальзамический пачули, подслащенный ванильностью и бобами тонка еще долго , очень долго остаётся на коже, одежде, вокруг..',
        perfume: 'Созвучен Chanel - coco Mademoiselle Intense',
      },
      {
        id: 43,
        name: 'F128',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f128.jpg',
        aroma: 'шипровый',
        top_notes: 'полынь, кардамон, розовый перец, мандарин',
        heart_notes:
          'ладан, гвоздика, древесные ноты, белые цветы, роза, перец, жасмин',
        base_notes: 'кожа, лабданум, кастореум, стиракс, дубовый мох, мускус',
        description:
          'Аромат - колдовское зелье - в него проваливаешься, словно в омут, из которого не хочется выбираться. Провоцирует, манит, притягивает. Открывается травяным, слегка горьковатым звучанием, с пряными оттенками полыни и специй под свежестью цитрусов. Сердце древесное и пряное, теплое благодаря блеску ладана, усиленное перцем и гвоздикой, чувственное подобно белым цветам, розе и жасмину. Кода аромата - сексуальный кожаный мускус с аккордами мха и смолы.',
        perfume: 'Созвучен Amouage - Memoir Woman',
      },
      {
        id: 44,
        name: 'F129',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f129.png',
        aroma: 'восточный, гурманский',
        top_notes: 'горький апельсин и лимон',
        heart_notes: 'роза, цветок апельсина',
        base_notes:
          'ваниль, миндаль, бобы тонка, толу бальзам, сандал, кашмеран, гелиотроп',
        description:
          'Сочный, смелый, сексуальный. Манит и завораживает восточными чарами. Роковой аромат современной и свободной натуры, знающей себе цену. Нетипично раскрывающиеся цитрусы на старте, едва заметное цветочное сердце, быстро переходящее в главный в этом аромате шлейф - ванильно-ликерный, сладковато - древесный, волшебный и очень долгий.',
        perfume: 'Созвучен Dior - Poison Girl',
      },
      {
        id: 45,
        name: 'F130',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f130.png',
        aroma: 'шипровый, цветочный',
        top_notes: 'красный апельсин, мандарин',
        heart_notes: 'мед, гардения, цветок апельсина, жасмин, персик',
        base_notes: 'пчелиный воск, карамель, пачули, лакричник',
        description:
          '«Медовый месяц» среди парфюмерии. Когда желание настолько велико, что выходит за пределы сознания. Тягучий мед в сердце аромата предельно нежен и чувственен. Цитрусы и карамель уравновешивают композицию, делая ее еще более интригующей и завлекающей. Пачули и Лакричник придают пикантности аромату в своем длинном, тягучем шлейфе, который абсолютно не способен надоесть.',
        perfume: 'Созвучен Jean Paul Gaultier - Scandal',
      },
      {
        id: 46,
        name: 'F131',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f131.jpg',
        aroma: 'цветочно-восточный',
        top_notes: 'белые цветы',
        heart_notes: 'мускус',
        base_notes: 'кашмеран',
        description:
          'Стиль и совершенство. Лаконичность и сдержанность. Нежность и чистота. Истинная база парфюмерии. Утонченность белых цветов, выверенные грани мускуса и теплота кашемирового дерева. Обладательница данного аромата - та, на которую смотрят, как на икону стиля, рассматривая детали, та, за которой хочется повторять, которой хочется вдохновляться.',
        perfume: 'Созвучен Narciso Rodriguez - Musc Noir For Her',
      },
      {
        id: 47,
        name: 'F132',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f132.jpg',
        aroma: 'цветочно-фруктовый',
        top_notes: 'розовый перец, ревень, киви',
        heart_notes: 'цикламен, жасмин, арбуз',
        base_notes: 'сандал, лимон, мускус',
        description:
          'Аромат - настроение летнего выходного дня. Сочный, сияющий и радостный. Невероятно оптимистичный и немного ностальгический, аппетитный, как любимая жвачка детства. Микс ярких фруктов, приправленных специями гармонично разбавляет чуть терпковатый ревень и мускус, создавая идеальную магическую гармонию.',
        perfume: 'Созвучен Dolce&Gabbana Anthology - L`Imperatrice 3',
      },
      {
        id: 48,
        name: 'F133',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f133.jpg',
        aroma: 'цветочный',
        top_notes: 'цветы, итальянский мандарин и красные фрукты',
        heart_notes: 'белая гардения, абсолют жасмина и цветок франжипани',
        base_notes: 'тростниковый сахар, пачули',
        description:
          'Головокружительный танец цветов, приправленный карамелью и освежающими цитрусами. Изящный и стильный, как дорогая винтажная мебель, умело вписанная дизайнером в современный интерьер. Естественный, но одновременно с этим аристократичный - для настоящей леди.',
        perfume: 'Созвучен Gucci - Flora Gorgeous Gardenia Eau De Parfum ',
      },
      {
        id: 49,
        name: 'F134',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f134.jpg',
        aroma: 'шипровый, цветочный',
        top_notes: 'лимон, кедр и цитрон',
        heart_notes: 'водяной гиацинт, розовый перец и жасмин',
        base_notes:
          'белый мускус, пачули, ветивер, тиковое дерево, ирис и амбра',
        description:
          'Как радость от долгожданного летнего дождя в жару, как глубокий вдох после погружения на глубину, как множество пузырьков в бокале освежающего тоника. Ласковый и игривый, освежающий и звонкий, нежный и бодрящий. Дыхание энергии и легкость, в которые затягивает аромат остаются с вами еще очень долгое время, даже, когда его уже почти не остается на коже.',
        perfume: 'Созвучен Chanel - Chance Eau Fraiche',
      },
      {
        id: 50,
        name: 'F135',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f135.jpg',
        aroma: 'восточный, цветочный',
        top_notes: 'миндаль, кофе, бергамот и лимон',
        heart_notes:
          'тубероза, жасмин самбак, цветок апельсина, корень ириса и болгарская роза',
        base_notes:
          'бобы тонка, какао, ваниль, пралине, сандал, мускус, амбра, кашемировое дерево, корица, пачули и кедр',
        description:
          'Аромат с характером. Богатый и многослойный. Сладостно - соблазнительный, уверенный и дерзкий. На старте громко и бесцеремонно заявляет о себе, но когда чуть плотнее «усаживается» на коже, удивляет и покоряет своим благородным звучанием с очень долгоиграющим шлейфом.',
        perfume: 'Созвучен CAROLINA HERRERA - Good Girl',
      },
      {
        id: 51,
        name: 'F136',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f135.jpg',
        aroma: 'цветочный',
        top_notes: 'сирень и бергамот',
        heart_notes: 'абсолют мимозы, ирис и роза',
        base_notes:
          'гелиотроп, бурбонская ваниль, корень ириса, ветивер и пачули',
        description:
          'Сладкий нежный сон, в котором вы в самом центре весеннего цветения cамых прекрасных цветов. Когда сладость окружающих ароматов обволакивает и погружает в негу, но абсолютно не кажется чрезмерной, потому что так красиво приправлена свежестью и невинностью еще не раскрывшихся бутонов. Когда голова кружится от ванильного удовольствия и предвкушения скорого пробуждения.',
        perfume: 'Созвучен Ex nihilo - sweet morphine ',
      },
      {
        id: 52,
        name: 'F137',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f135.jpg',
        aroma: 'цветочный',
        top_notes: 'альдегиды, роза и розовый перец',
        heart_notes: 'пион, фиалка и африканский апельсиновый цвет',
        base_notes: 'мускус, древесные ноты и сандал',
        description:
          'Свежесть прохладных простыней, неспешно накинутая на плечи смятая рубашка, вдох еще влажного от утренней росы воздуха. Аромат, пронизанный чистотой и естеством. Аромат, призванный не дополнять, а подчеркивать естественность и индивидуальность. Немного интимный, что только добавляет загадочности, наделяя эффектом парения в облаках и легкого сияния от счастья.',
        perfume: 'Созвучен Byredo - Blanche',
      },
      {
        id: 53,
        name: 'F138',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f135.jpg',
        aroma: 'цветочный',
        top_notes: 'нарцисс, ландыш, фиалка, жасмин, роза, персик и бергамот',
        heart_notes: 'альдегиды, розмарин и тубероза',
        base_notes:
          'цибетин, мускус, ветивер, сандал, бамбук, амбра и бобы тонка',
        description:
          'Изысканный, глубокий, винтажный. Для ценителей шедевров классики ХХ века. Аромат пронзает в самое сердце, доставая оттуда, одно за другим, теплые, чистые воспоминания, окутывая в томную ностальгическую вуаль. Поэтапное раскрытие безупречно cобранных нот, искусно связанных между собой альдегидами, делает аромат идеально сбалансированным и гармоничным.',
        perfume: 'Созвучен Lancôme - Climat Parfum Extrait',
      },
      {
        id: 54,
        name: 'F139',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f135.jpg',
        aroma: 'гурманский',
        top_notes: 'яблоко «Granny Smith» и бергамот',
        heart_notes: 'маракуйя, кедр и кориандр',
        base_notes: 'мускус, амбра и пачули',
        description:
          'Сочный, хрустящий, сладкий и звонкий старт, плавно смягчающее фруктовое буйство, но не затмевающее его, кедровое сердце с едва уловимой горчинкой и долгий, все еще фруктовый, но уже оттененный мускусом и древесными нотами, шлейф. Жизнерадостный и игривый аромат, легкий и заряжающий оптимизмом, для открытой, яркой и творческой натуры.',
        perfume: 'Созвучен Kajal -Dahab ',
      },
      {
        id: 55,
        name: 'F140',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/women/f135.jpg',
        aroma: 'восточный, гурманский',
        top_notes: 'красная смородина, розовый перец, груша и бергамот',
        heart_notes: 'роза, фиалка, пион и жасмин',
        base_notes:
          'ваниль, бобы тонка, бензоин, мускатный орех, ambroxan, пачули и древесные ноты',
        description:
          'Страстный, волнующий, сбивающий с ног цветочный вихрь, припудренный сладостью ванили и бобов тонка, гармонично приправленный специями и освежающими нотами фруктов и ягод смородины. Похоже на приятное будоражащее кровь любовное волнение, которое испытываешь к своему партнеру в пик наполненности и откровенности ваших отношений, когда приходит осознание, что выбор сделан правильно и больше никаких сомнений, когда остается только глубокая гордость напоказ и безудержная страсть наедине.',
        perfume: 'Созвучен Lancome - La Nuit Tresor a la Folie',
      },
    ],
    unisex: [
      {
        id: 56,
        name: 'U201',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u201.jpg',
        aroma: 'цветочный, фруктовый',
        top_notes: 'бергамот, личи и персик',
        heart_notes: 'жасмин, пион и апельсиновый цвет',
        base_notes: 'древесные, мох и мускус',
        description:
          'Аромат настоящего цветочного опьянения. Нетрадиционный и окутывающий цветочно-фруктовый аромат дополняет взрыв ярких цитруса и пиона и переходит в утонченный древесный шлейф. Для изысканных и одновременно эксцентричных, для тех, кому важно оставаться разными, но не терять своей сущности.',
        perfume: 'Созвучен Ex Nihilo - Fleur Narcotique',
      },
      {
        id: 57,
        name: 'U202',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u202.jpg',
        aroma: 'восточный, цветочный',
        top_notes: 'жасмин и шафран',
        heart_notes: 'древесный янтарь и серая амбра',
        base_notes: 'еловая смола и белый кедр',
        description:
          'Аромат многогранный, богатый, теплый и чувственный. Пряный шафран в сочетании с богатым жасмином сменяется аккордами благородного минерала янтаря и тонкой амбры и сливается с еловыми и древесными нотами. В сочетании с кожей аромат рождает неповторимое звучание.',
        perfume: 'Созвучен Maison Francis Kurkdjian - Baccarat Rouge 540',
      },
      {
        id: 58,
        name: 'U203',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u203.jpg',
        aroma: 'древесный, фужерный',
        top_notes: 'сандал, папирус',
        heart_notes: 'кедр из Вирджинии, кардамон, фиалка',
        base_notes: 'ирис, кожа и амбра',
        description:
          'Аромат настоящая провокация. Он в полной мере одновременно и мужской, и женский. Если сопоставлять с женским образом, тут несомненно сразу рисуется Пенелопа Крус в фильме "Бандитки". А для мужчин подойдет таинственный мачо-ковбой. Искры нот кардамона, фиалки и мускуса, обволакивающий и увлекающий аромат кожи и папируса, спокойные оттенки древесных нот... Безудержная страсть, сексуальность, чувственность и решительность.Пожалуй, такие эпитеты подойдут для описания этого парфюма.',
        perfume: 'Созвучен Le Labo - Santal 33',
      },
      {
        id: 59,
        name: 'U204',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u204.jpg',
        aroma: 'восточный, древесный',
        top_notes:
          'бергамот, лимон, апельсиновый цвет, бархатцы, черная смородина',
        heart_notes: 'фиалка, жасмин, цикламен',
        base_notes: 'амбра, мускус, ветивер, кедр из Вирджинии',
        description:
          'Аромат подобный настоящему темпераменту жителя африканского племени - сильный, независимый, танцующий и страстный. Яркая смесь цитрусов, освежающей смородины с романтическими нотками благородных фиалки и жасмина завершается древесным послевкусием.',
        perfume: "Созвучен BYREDO - Bal d'Afrique",
      },
      {
        id: 60,
        name: 'U205',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u205.jpg',
        aroma: 'шипровый, фруктовый',
        top_notes:
          'маракуйя, персик, малина, лист черной смородины, груша и песок',
        heart_notes: 'ландыш',
        base_notes: 'гелиотроп, сандал, ваниль, пачули и мускус',
        description:
          'Аромат сладкий, свежий и тропический, вдохновляющий. Вкусное начало из смеси маракуйи, персика, малины, груши и свежести листа черной смородины, сменяющееся нотками спокойного ландыша и завершающееся феерией чувств, словно прогулка по вечернему прохладному песку среди теплых ароматов сладкой выпечки и коктейлей.',
        perfume: 'Созвучен Tiziana Terenzi - Kirke',
      },
      {
        id: 61,
        name: 'U206',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u206.jpg',
        aroma: 'восточный, пряный',
        top_notes: 'табачный лист и специи',
        heart_notes: 'бобы тонка, цветок табака, ваниль и какао',
        base_notes: 'сухофрукты и древесные ноты',
        description:
          'Аромат главных героев фильма "Джентльмены". Легкая горчинка табака, загадочные ноты восточных специй в сочетании со сладкими ванилью и какао дополняются легкой свежестью древесных нот. Пряный, окутывающий, загадочный аромат.',
        perfume: 'Созвучен Tom Ford - Tobacco Vanille',
      },
      {
        id: 62,
        name: 'U207',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u207.jpg',
        aroma: 'цветочный, фруктовый, сладкий',
        top_notes: 'черная бузина, абрикос, черная орхидея и шампанское',
        heart_notes: '-',
        base_notes: 'махагони, взбитые сливки и черное дерево',
        description:
          'Аромат нетривиальный и искрящийся. Отсутствие сердечных нот делает этот аромат уникальным. Пленительная орхидея в сочетании с сочным абрикосом и яркими брызгами игристого сменяется уверенными древесными аккордами и игривыми взбитыми сливками.',
        perfume: 'Созвучен Zarkoperfume - Pink Molecule',
      },
      {
        id: 63,
        name: 'U208',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u208.jpg',
        aroma: 'фужерный',
        top_notes: 'бергамот и мандарин',
        heart_notes: 'зеленый чай и черная смородина',
        base_notes: 'гальбанум, мускус, сандал и петитгрейн',
        description:
          'Аромат свежий, звонкий, бодрящий и согревающий одновременно. Уникален тем, что и согреет, и охладит в зависимости от времени года- играет абсолютно по-разному. Бодрость цитрусов на старте, травяная свежесть в сочетании с черной смородиной в сердце аромата и приятный древесный шлейф.',
        perfume: 'Созвучен Creed - Silver Mountain Water',
      },
      {
        id: 64,
        name: 'U209',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u209.jpg',
        aroma: 'восточный',
        top_notes: 'амброксан',
        heart_notes: '-',
        base_notes: '-',
        description:
          'Аромат практически неуловимый на коже, подчеркивающий и усиливающий сексуальность подобно афродизиаку. Солоновато-сладкие ноты амбры с отдаленно уловимыми древесными оттенками мускуса , рождают головокружительную композицию.',
        perfume: 'Созвучен Escentric Molecules - Molecule 02',
      },
      {
        id: 65,
        name: 'U210',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u201.jpg',
        aroma: 'восточный, цветочный',
        top_notes: '-',
        heart_notes: '-',
        base_notes: 'жасмин, ирис, iso e super и амброксан',
        description:
          'Аромат, который будет оставаться с вами, даже если вам кажется, что он давно исчез. Основа аромата - два искусных афродизиака Амброксан и iso e super естественно сочетаются с благородными жасмином и ирисом, порождая незабываемую композицию, которая притягивает словно магнит внимание и интерес окружающих к источнику аромата.',
        perfume: 'Созвучен Escentric Molecules - Escentric 02',
      },
      {
        id: 66,
        name: 'U211',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u202.jpg',
        aroma: 'кожаный',
        top_notes: 'кардамон',
        heart_notes: 'лист Фиалки, жасмин',
        base_notes: 'кожа, дубовый мох, пачули',
        description:
          'Для тех, кто хочет подчеркнуть дерзость, сексуальность и стиль своего образа. Уверенное, но мягкое кожаное звучание и теплая пряность, сменяющие друг друга - основные аккорды, дополнены неожиданной цветочной легкостью фиалки и притягательностью изысканного жасмина, приправлены сладковато - древесным шлейфом. Все это делает звучание аромата спокойным, но уверенным, солидным и респектабельным, наполняет решительностью .',
        perfume: 'Созвучен Tom Ford - Ombre Leather 16',
      },
      {
        id: 67,
        name: 'U212',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u203.jpg',
        aroma: ' древесные пряные',
        top_notes: 'шафран, бессмертник, мандарин',
        heart_notes: 'лист Фиалки, османтус',
        base_notes: 'кожа, кедр, серая амбра',
        description:
          'Оригинальный, приятный, атмосферный аромат. Элегантная и неожиданная гармония мандарина, специй фиалки и замши. Все ноты аромата переплетаются и меняются между собой на протяжении звучания аромата, будто ведется диалог о светских новостях, марках дорогих авто и бизнесе где-то в современном кафе стеклянного офиса, где оппоненты берут слово в зависимости от поворота контекста беседы, иногда переходят на повышенные тона, но все же сходятся во мнении, принимая компетентность собеседника.',
        perfume: 'Созвучен Marc-Antoine Barrois - Ganymede',
      },
      {
        id: 68,
        name: 'U213',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u204.jpg',
        aroma: 'древесный, цветочный',
        top_notes: ' белый перец, гвоздика',
        heart_notes: ' гардения',
        base_notes: 'белый мускус, сандал',
        description:
          'Мягкий, освежающий, но при этом достаточно глубокий аромат, дарит ощущение прохлады и чистоты. Спокойный, нежный, не кричащий - чувствуется уют и забота. Так пахнет кожа после моря - тёплая солоноватость, присыпанная специями.',
        perfume: 'Созвучен Attar Collection - Musk Kashmir',
      },
      {
        id: 69,
        name: 'U214',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u205.jpg',
        aroma: 'фужерные',
        top_notes: 'карамель, табак, горький апельсин и красные ягоды',
        heart_notes: 'тубероза, лилия и орхидея',
        base_notes: 'ваниль и пачули',
        description:
          'Поражающий и ошеломляющий. Сначала сладкий и густой, как табачная дымка, окутывающая цветы... Затем табак рассеивается, отдавая власть цветам, приправленным десертной ванилью и карамелью, а табачную терпкость органично сменяет горький апельсин и ягоды.',
        perfume: 'Созвучен Franck Boclet - Cocaine',
      },
      {
        id: 70,
        name: 'U215',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u206.jpg',
        aroma: 'фужерный',
        top_notes: ' конопля и зеленые ноты',
        heart_notes: 'смолы, древесные ноты, табак и кофе',
        base_notes: 'уд и ладан',
        description:
          'Изысканный и дорогой. Весьма неоднозначный и провокационный аромат, призван разрушать устоявшиеся стереотипы и ограничения. Для уверенных, не боящихся власти и ответственности. Верхние конопляные и зеленые ноты звучат легко, но различимо, затем быстро сменяются сердечными древесными и тягучими табачно-кофейными нотами, оставляя за собой долгий и насыщенный шлейф.',
        perfume: 'Созвучен Nasomatto - Black Afgano',
      },
      {
        id: 71,
        name: 'U216',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u207.jpg',
        aroma: 'древесный, фужерный',
        top_notes: 'цедра лимона, лист горького апельсина',
        heart_notes: 'ароматные специи',
        base_notes: ' мускус, амбра, древесные ноты',
        description:
          'Уверенное терпко-цитрусовое начало, смягчается древесными нотами со специями, и тягучий сладко - мускусный шлейф, в который снова и снова возвращаются и переплетаются между собой все ноты пирамиды. Он как уикенд за городом двух влюбленных. Страстные и яркие объятия после разлуки, сменяющиеся теплыми приливами нежности в стенах уютного деревянного дома, и снова взрыв эмоций. Затем ностальгия, волнительное предвкушение и опять желание.',
        perfume: "Созвучен Thomas Kosmala - Apres l'Amour",
      },
      {
        id: 72,
        name: 'U217',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u208.jpg',
        aroma: 'фруктово-гурманский',
        top_notes: 'ревень, кофе, кардамон и мандарин',
        heart_notes: 'манго, имбирь, амбра',
        base_notes: 'ветивер, серая амбра, персик',
        description:
          'Аромат «американские горки» - для самых отважных. Его ноты раздаются так же неожиданно, как виражи на аттракционе, каждый раз удивляя и захватывая дух. Травянистая горечь параллельно со сладостью фруктов, терпкие специи, легкая дымка… Все это сливается в психоделическом танце, невероятно удивляет, даже ошеломляет, но как же сильно снова манит...',
        perfume: "Созвучен Vertus - Narcos'is",
      },
      {
        id: 73,
        name: 'U218',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u201.jpg',
        aroma: 'цветочный',
        top_notes: ' иланг-иланг, бергамот',
        heart_notes: 'гелиотроп, гедион, болгарская роза',
        base_notes: 'мирра, сандал',
        description:
          'Аромат - медитация. Спокойный, ровный, уравновешенный, ресурсный. Для тех, кому больше нечего доказать, потому что уже все доказано. Для уверенно идущих по намеченному пути, для придания еще большей уверенности каждому выверенному шагу. Идеально сбалансированная композиция цитрусовых, цветочных и древесных нот.',
        perfume: 'Созвучен Initio - Psychedelic Love',
      },
      {
        id: 74,
        name: 'U219',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u202.jpg',
        aroma: 'цветочно-древесный',
        top_notes: 'тубероза, персик, мандарин',
        heart_notes: 'гардения, жасмин, корень ириса',
        base_notes: 'ваниль, сандал, ветивер',
        description:
          'Сладкая вата со вкусом персика, купленная теплым вечером во время прогулки по набережной, усаженной деревьями с белыми цветами. Сладкий, бархатный, окутывающий, но с приятной древесно-свежей легкой терпкостью. Успокаивающий и предельно оптимистичный.',
        perfume: 'Созвучен NISHANE - Hundred Silent Ways',
      },
      {
        id: 75,
        name: 'U220',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u203.jpg',
        aroma: 'восточный, гурманский',
        top_notes: 'персик, красный апельсин, кардамон, гелиотроп',
        heart_notes: 'ром, коньяк, давана, жасмин',
        base_notes:
          'пачули из Индонезии, ваниль, сандал, бобы тонка, кашмеран, бензоин, стиракс, лабданум, ветивер',
        description:
          'Как спелый и сочный фрукт перед соблазнительностью которого сложно устоять. Опьяняющий и будоражащий, словно коктейль из крепкого напитка за счет человека за соседним столиком. Немного неожиданно островатый, как новое блюдо от шефа в фешенебельном ресторане. Утонченный и стильный как загадочный незнакомец. Нежный и манящий как самый красивый десерт на витрине в любимой кофейне. Деликатную сексуальность на грани слышно в каждой ноте аромата.',
        perfume: 'Созвучен Tom Ford - Bitter Peach',
      },
      {
        id: 76,
        name: 'U221',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u204.jpg',
        aroma: 'восточный',
        top_notes: 'персик, слива и цитрусы',
        heart_notes: 'белый табак, лист табака, мед и табак',
        base_notes: ' амбра, восточные ноты и дым',
        description:
          'Дымка табака и мерцающие оттенки сливы и персика, смешиваясь с золотистыми тонами медовых нот приглашают вас в свои объятья. Как любимый уютный плед из благородных материалов согревает и долго-долго остается с вами, наполняя самыми теплыми и приятными чувствами. «Уходит» медленно, возвышенно и достойно – в пряную прохладу. Невероятно стойкий и шлейфовый.',
        perfume: 'Созвучен Christian Dior - Tobacolor',
      },
      {
        id: 77,
        name: 'U222',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u205.jpg',
        aroma: 'восточный',
        top_notes: 'белый кедр и черный перец',
        heart_notes: 'уд и пачули',
        base_notes: 'бобы тонка и мускус',
        description:
          'Глубокое переосмысление привычной экзотики. Манящий аромат, где все ноты притягиваются друг к другу, создавая единую симфонию. Брутальный, немного покалывающий в носу черный перец на старте, чуть приглушенный благородным кедровым звучанием, сменяется легкой дымкой и приземленным пачули, смешивается с умеренной сладостью бобов тонка и мускуса. Прямолинейный, бескомпромиссный, насыщенный.',
        perfume: 'Созвучен Comme Des Garcons - Blackpepper',
      },
      {
        id: 78,
        name: 'U223',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u206.jpg',
        aroma: 'фужерный, гурманский',
        top_notes: 'кардамон и бергамот',
        heart_notes: 'яблоко, ром, бренди, ананас, ваниль и мох',
        base_notes: 'Ambroxan и кедр',
        description:
          'Благородный яблочный бренди раскрывается теплом вашей кожи. Несмотря на богатый букет, аромат не кажется чрезмерно перенасыщенным благодаря освежающей, подобно кубику льда в бокале, прохладе бергамота и древесных нот. Он элегантен и заманчив, как ночная жизнь элитного мегаполиса. И свежий, и тёплый, и пряный, и прохладный, и сладковатый, но настолько гармоничен, что абсолютно не вызывает отторжения.',
        perfume: 'Созвучен Kilian - Apple Brandy On The Rocks',
      },
      {
        id: 79,
        name: 'U224',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u207.jpg',
        aroma: 'восточный, гурманский',
        top_notes: 'кардамон, имбирь, красный апельсин и элеми',
        heart_notes: 'бурбонская ваниль и бензоин',
        base_notes:
          ' табак, мирра, ладан, какао, гваяк, пачули, папирус, ветивер и дубовый мох',
        description:
          'Безграничный, необъятный, временами мечтательный, определенно будоражащий сознание. Пряный, сладковатый, дымно-смолистый, но с освежающими цитрусово-имбирными нотами, аромат. Выделит в толпе из тысячи и окутает своей ванильной дымкой всех окружающих.',
        perfume: 'Созвучен Amouage - Boundless',
      },
      {
        id: 80,
        name: 'U225',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u208.jpg',
        aroma: 'древесный, фужерный',
        top_notes: ' лаванда, бергамот',
        heart_notes: 'ветивер, кедр и пачули',
        base_notes: 'сандал, мускус и гваяк',
        description:
          'Аромат - уход от суеты. Как медитация в горах, в окружении запахов горных цветов и благородных деревьев. Ощущение полного слияния с природой. Спокойный, уравновешенный. Удовольствие и наполненность от начала и до конца.',
        perfume: 'Созвучен Initio Parfums Prives - Rehab',
      },
      {
        id: 81,
        name: 'U226',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u209.jpg',
        aroma: 'цитрусовый, фруктовый',
        top_notes: '-',
        heart_notes: 'мандарин, Iso E Super',
        base_notes: 'сандал, мускус и гваяк',
        description:
          'Мандарин задает настроение и придает звонкой свежести аромату подобно задорной, танцующей росе ранним утром, олицетворяющей начало яркого и легкого дня. Позитивный настрой, вдохновляющий на успех с первого вдоха аромата, будет сопровождать в течение всего дня. Сочный и искрящийся, слегка пикантный, определенно вдохновляющий.',
        perfume: 'Созвучен Escentric Molecules - Molecule 01 + Mandarin',
      },
      {
        id: 82,
        name: 'U227',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u201.jpg',
        aroma: 'цитрусовый, фруктовый',
        top_notes: 'кокос, черная смородина и мандарин',
        heart_notes: 'цветок апельсина и франжипани',
        base_notes: 'ваниль и сухая древесина',
        description:
          'Сладкий и яркий, ясный и бодрящий, как отпуск на побережье кристально чистого и голубого океана с его тропическими плодами и полной беспечностью. Абсолютная беззаботность и райское наслаждение.',
        perfume: 'Созвучен Thary - Surmonter',
      },
      {
        id: 83,
        name: 'U228',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u202.jpg',
        aroma: 'цветочный, древесный',
        top_notes: 'китайский османтус, зеленый мандарин и бергамот',
        heart_notes: 'мимоза, мастиковое дерево, нарцисс и корень ириса',
        base_notes: 'сандал, кедр, гваяк и ветивер',
        description:
          'Утонченный и гармоничный, энергичный, но в то же время приземляющий и мягкий. Зеленый, цветочный и пудровый, с легкой весенней мимозой - символом пробуждения природы - в сердце аромата. Освежают и задают настроение вечно зеленый китайский османтус и цитрусы, а изысканные ноты благородного ириса ласкают обоняние. Окутывающая древесная база идеально уравновешивает аромат.',
        perfume: 'Созвучен The Harmonist - Magnetic Wood',
      },
      {
        id: 84,
        name: 'U229',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u203.jpg',
        aroma: 'цветочный, шипровый',
        top_notes: 'маракуйя, лист черной смородины, лимон и папоротник',
        heart_notes: 'гвоздика (цветок), ландыш и чайная роза',
        base_notes: 'бобы тонка, мускус и сандал',
        description:
          'Именно такой аромат выбирали бы для себя представители аристократии XVIII-XIX веков, если бы у них был выбор. Именно так благоухает утренний сад перед фамильным поместьем, ароматы которого усиливает утренняя роса и они легко уловимы сквозь распахнутые ставни, куда так мягко проступают первые солнечные лучи. Цветочный и пудровый, но очень элегантный и изысканный.',
        perfume: 'Созвучен Tiziana Terenzi - Cassiopea',
      },
      {
        id: 85,
        name: 'U230',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u204.jpg',
        aroma: 'шипровый',
        top_notes: ' ананас, грейпфрут и бергамот',
        heart_notes: 'кедр, пачули и жасмин',
        base_notes: ' дубовый мох и древесные ноты',
        description:
          'Стильный и элегантный, как классический дорогой автомобиль. Может показаться дерзким из-за терпкого грейпфрута на старте, но уже спустя несколько мгновений полностью меняет представление, раскрывая свое истинное и уникальное звучание. Грейпфрут сменяет свою дерзость на сочность, его искусно дополняют звонкий бергамот и сладкий ананас. Древесно - цветочное сердце завораживает, а дубовый мох ненавязчиво добавляет нотки зрелости и изысканности аромату.',
        perfume: 'Созвучен Nishane - Hacivat',
      },
      {
        id: 86,
        name: 'U231',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u205.jpg',
        aroma: 'восточный, гурманский',
        top_notes: 'гардения, гвоздика (цветок) и жасмин',
        heart_notes: 'пачули, цветочные ноты, сандал и морские ноты',
        base_notes: 'карамель и кофе',
        description:
          'Сладкая жизнь элиты: загородные особняки с прекрасными цветущими садами; ночные вечеринки с яркими огнями и светящимися вывесками фешенебельных баров и ночных клубов никогда не спящего мегаполиса; морские прогулки на белоснежных яхтах. Уверенность, достоинство и наслаждение. Яркий, но спокойный, заявляющий, но не кричащий, богатый, но выверено-сдержанный аромат для уверенных в себе личностей.',
        perfume: 'Созвучен New York Nights - Bond No.9',
      },
      {
        id: 87,
        name: 'U232',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u206.jpg',
        aroma: 'восточный, гурманский',
        top_notes: 'коньяк',
        heart_notes: 'корица, бобы тонка и дуб',
        base_notes: 'пралине, ваниль и сандал',
        description:
          'Душевный и теплый, как уикенд в большом загородном доме с друзьями или родными, где вас встречает витающий аромат свежеиспеченного яблочного пирога. Неспешные прогулки до захода солнца и долгие вечерние беседы перед камином с бокалом благородного выдержанного коньяка. Пряный, уютный и ностальгический.',
        perfume: "Созвучен Kilian - Angels' Share",
      },
      {
        id: 88,
        name: 'U233',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u207.jpg',
        aroma: 'древесный, фужерный',
        top_notes: 'ягоды можжевельника',
        heart_notes: 'гваяк',
        base_notes: 'нагармота и пачули',
        description:
          'Аромат свежести леса после дождя. Смело стартует изысканно-терпкими ягодами можжевельника, которые тут же обволакивает и смягчает дымный, немного смолистый гваяк. Чуть позже чувствуются проступающие древесные и землистые ноты нагармоты и пачули. Густой шлейф остается с вами надолго, увлекая, словно густой лес за собой все глубже и глубже.',
        perfume: 'Созвучен Matiere Premiere Bois d`Ebene',
      },
      {
        id: 89,
        name: 'U234',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u205.jpg',
        aroma: 'восточный, цветочный',
        top_notes: 'экзотические цветы и специи',
        heart_notes: 'гвоздика (цветок) и ландыш',
        base_notes: 'амбра, ваниль и мускус',
        description:
          'Чувственный, мощный и соблазнительный. Сексуальные экзотические цветы, приправленные специями, буквально ошеломляют при первом вдохе. Но аромат на этом не собирается останавливаться, а только набирает обороты: тягучий и пушистый ладан с ванильными нотками влечет в сладострастные объятия и долго не отпускает. На протяжении всего звучания аромат переливается, вспыхивая то цветами, то специями, то неожиданно уютной и нежной теплотой кожи. Затем все повторяется снова и снова.',
        perfume:
          'Созвучен Rosendo Mateu № 5  Rosendo  Floral, Amber, Sensual Musk Rosendo Mateu Olfactive Expressions',
      },
      {
        id: 90,
        name: 'U235',
        price: 18000,
        count: 1,
        visible: true,
        img: '../perfumes/unisex/u206.jpg',
        aroma: 'восточный, гурманский',
        top_notes: '-',
        heart_notes:
          ' кофе, ром, карамель, гелиотроп, сахарный тростник, тёмный шоколад, сандаловое дерево, миндаль',
        base_notes: 'амбра, ваниль и мускус',
        description:
          'Аромат - провокация. Насколько далеко вы готовы зайти? Насколько вы смелые, чтобы носить его с гордостью? Насколько вы экстравагантны, чтобы признать его потрясающим? Насыщенный и головокружительный, местами ошеломляющий и вгоняющий в ступор, но определенно заостряющий на себе внимание. Пленительный и обольщающий.',
        perfume: 'Созвучен Kilian - Black Phantom Memento Mori',
      },
    ],
    diffusers: [
      {
        id: 91,
        name: 'Диффузор D001',
        price: 20000,
        count: 1,
        visible: true,
        img: '../diffusers/D001.png',
        aroma:
          'цитрусовый, тёплый пряный, дымный, фужерный, кожаный, ванильный',
        top_notes: 'померанец, береза.',
        heart_notes: 'красный виноград, роза, магнолия.',
        base_notes: 'фиалка, палисандр, корица.',
        description:
          'Оптимистичная и чувственная история. Наполнит дом сладко-пряным, цитрусовым ароматом, а вместе с ним - теплыми воспоминаниями о лучших путешествиях в солнечные страны или о самых незабываемых свиданиях. Постепенное раскрытие композиции напоминает хронологию развития очередного неспешного дня в отпуске, бодро начинающегося с прогулок вдоль дикорастущих апельсинов и экскурсий на местные виноградники, затем плавно переносящегося в вечерние дегустации пряных напитков и завершающийся страстными танцами, отдавая волю чувствам. Подарит ощущение свежести, придаст бодрости духа. С первого вдоха превратит рутинные дела в легкий и приятный досуг. Подойдет для ароматизации гостиной, прихожей, студии танцев, помещений перед свиданием.',
      },
      {
        id: 92,
        name: 'Диффузор D002',
        price: 20000,
        count: 1,
        visible: true,
        img: '../diffusers/D002.png',
        aroma:
          'тёплый пряный, древесный, ванильный, амбровый, пачулиевый, фужерный, пудровый, землистый, сладкий',
        top_notes: 'апельсин, фиалка, магнолия.',
        heart_notes: 'слива, клубника, красные ягоды.',
        base_notes: 'кедр.',
        description:
          'Аромат - радость возвращения в любимый дом. Такой знакомый и желанный. Как долгожданная поездка к бабушке за город жарким летом. Дивный сад со спелыми сливами и ароматными ягодами. Симпатичные разноцветные фиалки на окнах. Яркий и жизнерадостный. Аромат, который заставит улыбаться каждый раз, когда вы окажетесь на пороге, оставляя все тягости и проблемы за его пределами помещения. Подойдет для ароматизации прихожей, детской, столовой, магазина одежды или товаров для дома.',
      },
      {
        id: 93,
        name: 'Диффузор D003',
        price: 20000,
        count: 1,
        visible: true,
        img: '../diffusers/D003.png',
        aroma:
          'тёплый пряный, древесный, амбровый, свежий пряный, удовый, цитрусовый, кожаный, мускусный, свежий',
        top_notes: 'цветы апельсина.',
        heart_notes: 'кедр, красный перец, кипарис.',
        base_notes: 'сандал, амбра.',
        description:
          'Глубокий, обволакивающий, приглашающий аромат. Наполнит пространство живой энергией, создавая идеальную плодотворную ауру для концентрации внимания. Дымно-древесный аромат с освежающими акцентами и слегка ментоловым подтоном. Красный перец изящно и нетривиально раскрывает ноты аромата, а амбра придает ему невероятную чувственность и проницаемость. Подойдет для ароматизации кабинета, офиса, прихожей.',
      },
      {
        id: 94,
        name: 'Диффузор D004',
        price: 20000,
        count: 1,
        visible: true,
        img: '../diffusers/D004.png',
        aroma:
          'амбровый, ванильный, пудровый, фужерный, сладкий, землистый, фруктовый',
        top_notes: 'ваниль, ладанник.',
        heart_notes: 'ирис, пачули.',
        base_notes: 'амбра, сандал.',
        description:
          'Аромат дорогой мебели и роскошных тканей. Загадочный, пленительный, восточный. Придаст помещению легкой пафосности статусных особняков, но сделает это элегантно, совсем не вычурно. Завораживающая композиция с оттенками золота и драгоценных камней. Подойдет для ароматизации гостиной, переговорной, парадной, а также для пространств, требующих создания мистической атмосферы (квесты, гадания, тематические вечеринки) .',
      },
      {
        id: 95,
        name: 'Диффузор D005',
        price: 20000,
        count: 1,
        visible: true,
        img: '../diffusers/D005.png',
        aroma:
          'сладкий, ореховый, тёплый пряный',
        top_notes: 'пиразины (запах корочки хлеба).',
        heart_notes: 'яблоко, корица.',
        base_notes: 'кумин, ваниль.',
        description:
          'Аромат свежеиспеченных вафель утром выходного дня. Невероятно уютный и мечтательный. Буквально заставляет погрузиться в негу душевного тепла и умиротворения. Способен как пробудить и вдохновить на великие свершения, так и заботливо укрыть, словно мягким пледом, погрузив в состояние полного релакса. Подойдет для гостиной, детской, столовой, спальни.',
      },
      {
        id: 96,
        name: 'Диффузор D006',
        price: 20000,
        count: 1,
        visible: true,
        img: '../diffusers/D006.png',
        aroma:
          'фруктовый, белые цветы, зелёный, пачулиевый, цитрусовый, пудровый, тёплый пряный, свежий пряный',
        top_notes: 'манго, апельсин.',
        heart_notes: 'розовый перец, бергамот.',
        base_notes: 'ваниль, белый мускус.',
        description:
          'Экзотический, свежий, фруктовый аромат манго в сочетании с цитрусовыми и капелькой терпкости розового перца. Сочный и яркий, сладкий и насыщенный, как освежающий коктейль на вечеринке. Погружает в атмосферу веселья и драйва. Подойдет для ароматизации помещений для вечеринок, клубов, гостиной, магазинов товаров для развлечений и игрушек.',
      }
    ],
  });

  const [basket, setBasket] = useState(
    JSON.parse(localStorage.getItem('basket')) || []
  );

  useEffect(() => {
    localStorage.setItem('basket', JSON.stringify(basket));
  }, [basket]);

  const [searchResult, setSearchResult] = useState([]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <Header
              perfumes={perfumes}
              searchResult={searchResult}
              setSearchResult={setSearchResult}
            />
          }
        >
          <Route
            path={'men'}
            element={
              <Men men={perfumes.men} basket={basket} setBasket={setBasket} />
            }
          />
          <Route
            path={''}
            element={
              <Women
                women={perfumes.women}
                basket={basket}
                setBasket={setBasket}
              />
            }
          />
          <Route
            path={'unisex'}
            element={
              <Unisex
                unisex={perfumes.unisex}
                basket={basket}
                setBasket={setBasket}
              />
            }
          />
          <Route
            path={'diffusers'}
            element={
              <Diffusers
                diffusers={perfumes.diffusers}
                basket={basket}
                setBasket={setBasket}
              />
            }
          />
          <Route
            path={'perfume/:id'}
            element={
              <Page perfumes={perfumes} basket={basket} setBasket={setBasket} />
            }
          />
          <Route
            path={'basket'}
            element={<Basket basket={basket} setBasket={setBasket} />}
          />
          <Route
            path={'catalog'}
            element={
              <Catalog
                basket={basket}
                setBasket={setBasket}
                searchResult={searchResult}
                setSearchResult={setSearchResult}
              />
            }
          />
          <Route path={'company'} element={<About />} />
          <Route path={'payment'} element={<TermsOfPayment />} />
          <Route path={'delivery'} element={<Delivery />} />
          <Route path={'cert'} element={<Certificate />} />
          <Route path={'*'} element={<NotFound />} />
        </Route>
      </Routes>
      <BasketBtn basket={basket} />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
